<template>
  <div>
    <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
  </metainfo>
    <Header></Header>
    <MobileNav></MobileNav>
    <!-- <keep-alive v-if="this.$route.name=='Home'"><component :is="components.home" /></keep-alive>
    <keep-alive v-if="this.$route.name=='PostView'"><component :is="components.postView" /></keep-alive>
    <router-view v-else></router-view> -->
     <router-view></router-view> 
    <bottom-footer></bottom-footer>
  </div>
</template>
<script>
// import Home from '@/views/Home'
// import postFullview from '@/views/postFullview'

export default {
  // components: { Home ,postFullview },
  data () {
      return {
        components: { home:"Home" ,postView:"postFullview"}
      }
  },
  watch:{
    "$route.name":function(){
     
    }
  }

}
</script>