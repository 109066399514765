<template>
    <div class="post-comments-list">
        <div v-if="commentList">
            <div v-for="(item, index) in commentList" :key="item._id">
                <commentSingle :commentData="item" :data-idx="index"></commentSingle>
            </div>
            <div class="loadmore" v-if="next">
                <a href="" @click.prevent="getNextPage" v-if="!loader">View more comments</a>
            </div>
            <div class="commentCreate">
                <div class="media m-0">
                    <div class="d-flex mr-3">
                        <figure>
                            <img class="img-circle w45" :src="userLoggedIn.userDp?userLoggedIn.userDp:'https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg'" alt=""/>
                        </figure> 
                    </div>
                    <div class="media-body">
                        <textarea v-model="newComment" class="comment-create" placeholder="Write a comment"></textarea>
                    </div>
                    <div class="d-flex">
                        <a class="createcomment" href="#" @click.prevent="createComment"><i class="mico-feather-23"></i> </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.loadmore a{
    color: var(--secondary-color);
    font-weight: 600;
}
.commentCreate[data-v-0f31be49] {
    position: sticky;
    width: 100%;
    min-height: 60px;
    background: var(--white-clr);
    bottom: 0%;
    left: 0%;
    padding: 15px 0px;
}
.commentCreate .comment-create{
    width: 100%;
    border: none;
    background: var(--white-clr2);
    border-radius: 10px;
    resize: none;
    padding: 14px 14px 14px 14px;
    overflow: hidden;
    min-height: 46px;
}
.commentCreate .createcomment{
   width: 40px;
    height: 40px;
    text-align: center;
    background: var(--dark-color);
    color: var(--white-clr);
    font-size: 20px;
    line-height: 2.3;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
}
.commentCreate .createcomment i{
    display: inline-block;
   
}
</style>
<script>
import helpers from '@/plugins/helpers.js'
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
import  commentSingle from './commentSingle'
export default {
     components: { commentSingle },
     props: {
        postId: {
            type:String,
            default: ''
        },
    },
    computed: {
        ...mapGetters({ authToken: 'authToken',userLoggedIn:'userData'}),
    },
    data () {
        return {
            page:1,
            next:0,
            commentList:[],
            loader:false,
            newComment:''
        }
    },
    beforeMount() {
        if(this.authToken) this.getComments()
    },
    mounted() {
        var textarea = document.querySelector('.comment-create');
        textarea.addEventListener('keydown', autosize);
        function autosize(){
            var el = this;
            setTimeout(function(){
                el.style.cssText = 'height:auto; padding:0';
                el.style.cssText = 'height:' + el.scrollHeight + 'px';
            },0);
}
    },
    methods: {
        getComments: function () {
            var self = this
            if (self.page == 0 ) return false;
            self.loader=true
            axios.get('/api/postact/comment/?page='+self.page+'&postId='+self.postId).then(response => {
                self.page = response.data.data.next;
                self.next = response.data.data.next;
                response.data.data.list.forEach(item => { self.commentList.push(item)});
                self.loader=false
            }).catch(error => {
                    self.loader=false
                    self.page =0
            })
        },
        getNextPage:function(){
            this.getComments()
        },
        createComment:async function(){
            if(this.newComment.length>0 && this.loader==false){
                this.loader=true;
                let resp= await axios.post('/api/postact/comment/', {postId:this.postId , comment: this.newComment}).then();
                if(resp.data){
                    var cmt = resp.data.data.comment.comments[0]
                    if(cmt.user == this.userLoggedIn.userId){
                        cmt.user={};
                        cmt.user._id = this.userLoggedIn.userId,
                        cmt.user.fullName = this.userLoggedIn.fullName
                        cmt.user.username = this.userLoggedIn.username
                        cmt.user.userDp = this.userLoggedIn.userDp
                        this.commentList.unshift(cmt);
                        this.$parent.postData.commentCount = resp.data.data.commentCount;
                        this.newComment="";
                         document.querySelector('.post-comments-list').scrollTop = 0;
                    }
                    //this.$parent.postData.commentCount = resp.data.data.commentCount;
                }
                this.loader=false
            }
        }
    }
}
</script>