export default {
    setCookie(name, value, days = false) {
        var expires = "";
        if (days) {
            var date = new Date();
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    timeSince(date) {
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        if (interval > 1) { return Math.floor(interval) + " years";}
        interval = seconds / 2592000;
        if (interval > 1) {return Math.floor(interval) + " months";}
        interval = seconds / 86400;
        if (interval > 1) { return Math.floor(interval) + " days";}
        interval = seconds / 3600;
        if (interval > 1) { return Math.floor(interval) + " hours";}
        interval = seconds / 60;
        if (interval > 1) { return Math.floor(interval) + " minutes";}
        return Math.floor(seconds) + " seconds";
    },
    isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }
}