<template>
    <div>
    <div class="media m-0">
        <div class="d-flex mr-3">
            <router-link :to="'/'+(commentData.user.username?commentData.user.username:commentData.user._id)">
                <figure>
                    <img class="img-circle w45" :src="commentData.user.userDp?commentData.user.userDp:'https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg'" alt=""/>
                </figure> 
            </router-link>
        </div>
        <div class="media-body">
            <h4 class="m-0 u-name">
                <router-link :to="'/'+(commentData.user.username?commentData.user.username:commentData.user._id)">{{(commentData.user.username?commentData.user.username:commentData.user._id)}}</router-link>
            </h4>
            <small class=""><span>{{timeSince}}</span></small>
            <div class="commentBox">
                <div  v-if="!cmtEditorActive">
                    <p v-html="formatedComment"></p>
                    <a class="desc-togg" href="#" @click.prevent="togglFullText" v-if="postDescShorted">See more</a>
                    <a class="desc-togg" href="#" @click.prevent="togglFullText" v-if="hideConToggler">See less</a>
                </div>
                 <div class="inlineEdit" v-else>
                    <textarea v-model="editContent" class="edit-post-content" placeholder="Write a caption..."></textarea>
                    <div class="btn-area">
                        <i class="mico-feather-15 save-p" @click.prevent="savePost"></i>
                        <i class="mico-close-round cancel" @click.prevent="editPostCancel"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div class="d-inline-flex" v-if="userLoggedIn.userId==commentData.user._id">
                <DropDown dropdownHandle='<i class="mico-android-more-horizontal"></i>' class="user-post-actions" :menuList="commentMenu"></DropDown>
            </div>
        </div>
        </div>
    </div>
</template>
<style>
.commentBox{
    width: 100%;
}
.commentBox p{
    word-break: break-all;
    margin: 6px 0px;
}
.commentBox p a{
    font-weight: 500;
    color: var(--secondary-color);
}
</style>
<script>
import helpers from '@/plugins/helpers.js'
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
export default {
    props: {
        commentData:{ type:Object , default:false},
    },
    components: {
		
	},
    computed: {
        ...mapGetters({ authToken: 'authToken',userLoggedIn:'userData'}),
        timeSince() { return helpers.timeSince(new Date(this.commentData.time)) +' ago'},
    },
    mounted () {
        this.postConFormat()
    },
    data() {
        return {
             commentMenu:[
                { menuInner:'Edit Comment' ,icon:'mico-feather-3',action:this.editcoment},
                { menuInner:'Delete Comment' ,icon:'mico-trash-bin',action:this.deleteComment},
            ],
            cmtEditorActive:false,
            trimLen:160,
            formatedComment:'',
            postDescShorted:false,
            hideConToggler:false,
            editContent:'',
            loading:false
        }
    },
    methods: {
        editcoment:function(){
            this.cmtEditorActive = true;
            this.editContent = this.commentData.content
        },
        editPostCancel(){
             this.cmtEditorActive = false;
             this.editContent ="";
             this.postConFormat();
        },
        deleteComment:function(){
            var self =this;
             axios.delete('/api/postact/comment/',  {data:{ postId:  self.$parent.postId , commentId: self.commentData._id } }).then(res=>{ 
                let objIndex = self.$parent.commentList.findIndex((o) => o._id === self.commentData._id);
                if (objIndex > -1) { self.$parent.commentList.splice(objIndex, 1) }
                swal("Post deleted.", {icon: "success"});
            }).catch(error=>{
                console.log(error)
                swal("Unable to delete post.", {icon: "error"});
            })
        },
         async savePost(){
            if (this.editContent == this.commentData.content || this.loading) return false;  
            var self =this;
            self.loading =true
            let resp= await axios.put('/api/postact/comment', { postId:  self.$parent.postId , commentId: self.commentData._id , comment: self.editContent }).then();
            if(resp.data){
                self.commentData.content = this.editContent;
                let objIndex = self.$parent.commentList.findIndex((o) => o._id === self.commentData._id );
                self.$parent.commentList[objIndex] = self.commentData;
                self.cmtEditorActive = false;
                self.postDescShorted = false,
                self.hideConToggler = false,
                self.loading =false;
                self.postConFormat();
            }else{
                 self.loading =false;
            }

        },
         postConFormat(){
            var text = (this.commentData.content && this.commentData.content !="undefined")?this.commentData.content:''
            if(this.postDescShorted==false){
                text= text.substring(0, this.trimLen);
                if(this.commentData.content.length>this.trimLen)  this.postDescShorted=true;
            }else{
                this.postDescShorted=false
            }
            text = text.replace(/(\b(https?|http):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/img, '<a href="$1" target="_blank">$1</a>');
            text = text.replace(/#(\w+)/g, '<a href="'+window.location.origin+'/hashtag/$1"> #$1 </a> '); 
            text = text.replace(/@(\w+)/g, '<a href="'+window.location.origin+'/$1"> @$1 </a> ');
            if(this.postDescShorted==false) text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            if(this.postDescShorted) text+='...'
            this.formatedComment =  text;
        },
    },
    
}
</script>