import header from '@/components/header-public.vue'
import bottomFooter from '@/components/bottom-footer.vue'
import loader from '@/components/loader.vue'
import PostListItem from '@/components/postListItem.vue'
import profilePostItem from '@/components/profilePostItem.vue'
import MobileNav from '@/components/MobileNav.vue' 
import createPost from  '@/components/createPost.vue'
import DropDown from  '@/components/DropDown.vue'
import postViewer from  '@/components/postFullview.vue'
import cmtViewer from  '@/components/commentsView.vue'
export default {
    register(app){
        app.component('Header', header);
        app.component('bottom-footer', bottomFooter);
        app.component('Loader', loader);
        app.component('PostListItem', PostListItem);
        app.component('ProfilePostItem', profilePostItem);
        app.component('MobileNav', MobileNav);
        app.component('createPost', createPost);
        app.component('DropDown', DropDown);
        app.component('PostViewer', postViewer);
        app.component('cmtViewer', cmtViewer);
    }
 }