<template>
  <div >
    <div
      class="explorebox" :data-post-id="postItem._id"
      :style="`background: linear-gradient(rgba(34, 34, 34, 0.2),rgba(34, 34, 34, 0.2)),url('`+postItem.attachment[0].file_url+`')no-repeat`"
      style="background-size: cover; background-position: center center; -webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;"
     
    >
    <a href="#" class="delete-post" v-if="postItem.postAuthor._id == userId"    v-on:click.prevent="deletePost(postItem._id)"><i class="mico-trash-bin"></i></a>
    </div>
  </div>
</template>
<style>
.delete-post{
 position: absolute;
    font-size: 30px;
    top: 10px;
    right: 10px;
    color: var(--primary-color);
    text-shadow: 0px 0px 4px rgb(0 0 0 / 59%);
    z-index: 1;
}
</style>
<script>

import axios from 'axios'

export default {
  props: {
    postItem: {
      type: Object,
      default: {},
    },
  },
  computed: {
    timeSince() {},
    
  },
 data () {
  return {
    userId:store.getters.userData.userId
  }},
  mounted(){
    
  },
  methods: {
    deletePost(postId){
     var s=  confirm("Are you sure you want to delete this post?")
     if(!s) return false
      axios.delete('/api/userpost',  {data:{ postId: postId }}).then(res=>{ 
        location.reload()
      })
      //  
    }
  }
};
</script>   