<template>
<div>
  <nav v-if="isMob && currUrl !='Home'" class="mb-pb">
    <ul class="comm-menu"> 
        <li><router-link to="/feed" exact-path><i class="mico-feather"></i></router-link></li>
        <li v-if="authToken">
          <a href="#" @click.prevent="showCreatePost=(showCreatePost)?false:true" ><i class="mico-plus-square-o"></i></a>
          <!-- <router-link to="/create"><i class="mico-plus-square-o"></i></router-link> -->
        </li>
        <li v-if="!authToken"><router-link to="/launchpad" exact-path ><i class="mico-line-awesome"></i></router-link></li>
        <li v-if="!authToken"><router-link to="/marketplace" exact-path ><i class="mico-shop"></i></router-link></li>
        <li v-if="!authToken"><router-link to="/staking" exact-path ><i class="mico-feather-10"></i></router-link></li>
        <li v-if="authToken">
          <DropDown :dropdownHandle='userProfileMenu.main' class="user-meb-menu" :menuList="userProfileMenu.list"></DropDown>
        </li>
        <li v-else>
          <router-link to="/auth/login" exact-path ><i  class="mico-feather-9"></i></router-link>
        </li>
    </ul>
  </nav>
  <nav v-if="isMob && currUrl =='Home'" class="mb-pb">
    <ul class="comm-menu">
       <li><a href="/feed" exact-path><i class="mico-feather"></i></a></li>
       <li v-if="authToken">
          <DropDown :dropdownHandle='userProfileMenu.main' class="user-meb-menu" :menuList="userProfileMenu.list"></DropDown>
        </li>
        <li v-else><a href="/auth/login" exact-path > <i  class="mico-feather-9"></i> </a>
        </li>
    </ul>
  </nav>
  <createPost v-if="showCreatePost"></createPost>
  </div>
</template>
<style>

.user-meb-menu .user-dp {
    width: 26px;
    border-radius: 50%;
    border: 1px solid var(--dark-color);
}
.user-meb-menu .dropdown{
  top: unset;
  bottom: 118%;
}
.user-meb-menu .btn{
    padding: 0px;
    width: auto;
    background: transparent !important;
}
</style>
<style scoped>
  .mb-pb .comm-menu{
    display: flex;
    justify-content:space-between!important;
}
.mb-pb {
  width: 100%;
  position: fixed;
  bottom: 0px;
  min-height: 30px;
  padding: 4px 0px 2px 0px;
  background-color: #fff;
  box-shadow: 0 0 18px 0 rgb(0 0 0 / 20%);
  z-index: 100;
}

.comm-menu li a{
    width: auto;
}

.mb-pb .has-dropdwon .dropdown{
    top: unset;
    bottom: 120%;
}
.mb-pb .has-dropdwon .dropdown li{
    width: 100%;
    display: flex;
    margin:0px
}
.mb-pb .has-dropdwon .dropdown li a{
    width: 100%;
    display: flex;
    padding: 12px 14px 12px 14px;
}
.mb-pb .has-dropdwon .dropdown li a i,
.mb-pb .has-dropdwon .dropdown li a span{
    display: inline-flex;
}
.mb-pb .has-dropdwon .dropdown li a span{
        padding-left: 15px;
}
.mb-pb .comm-menu li a:hover{
    background: transparent;
}
.mb-pb .comm-menu li a{
    padding: 0px;
}
.mb-pb .comm-menu>li:nth-child(2) a i{
    font-size:28px
}
</style>
<script>
import helpers from "@/plugins/helpers.js";
import { mapGetters } from 'vuex'
export default {
    data() {
     return {
      isShow:true,
      showCreatePost:false,
      isMob:helpers.isMobile()?true:false,
       currUrl: this.$route.name,
      userProfileMenu:{
        main:'<img class="user-dp" src="https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg">',
        list:[
          { link:'/', menuInner:'Profile' ,icon:'mico-user-outline'},
          { link:'/mcakewallet', menuInner:'MCAKE Wallet' ,icon:'mico-logo'},
          { link:'/settings', menuInner:'Settings' ,icon:'mico-settings-streamline-1'},
          { link:'/settings/wallets', menuInner:'Manage Wallets' ,icon:'mico-vallet'},
          { link:'/logout', menuInner:'Logout' ,icon:'mico-sign-out'},
        ]
      }
    }
  },
  mounted () {
    this.isShow =false;
     this.userProfileMenu.main = '<img class="user-dp" src="'+(this.userData.userDp?this.userData.userDp:'https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg')+'">'
    this.userProfileMenu.list[0].link="/"+(this.userData.username?this.userData.username:this.userData.userId)+''
  },
  methods: {},
  watch:{
    $route (to, from){
        this.isShow = false;
    },
    
} ,
  computed: {
    ...mapGetters({
      authToken: 'authToken',
      userData:'userData'
    })
  }
};
</script>