<template>
    <div class="has-dropdwon" :class="(isShow)?'active':''"  v-if="menuList.length">
        <a class="btn" v-html="dropdownHandle"  @click.prevent="toggler()"></a>
        <ul class="dropdown  p-4 rounded-3" v-if="isShow">
            <li v-for="(item , i ) in menuList" :key="item.text" :class="(i == menuList.length-1)?'':'mb-4'"  @click.prevent="isShow=false">
                <router-link v-if="item.link" :to="item.link"  exact-path>
                     <i v-if="item.icon" :class="item.icon" class="me-3"></i>
                     <span v-html="item.menuInner" class="me-4"></span>
                </router-link>
                <a v-else-if="item.action" @click="item.action(actionId)">
                     <i v-if="item.icon" :class="item.icon" class="me-3"></i>
                     <span v-html="item.menuInner" class="me-4"></span>
                </a>
            </li>
        </ul>
    </div>
</template>
<style scoped>
    .dropdown a{
        cursor: pointer;
    }
</style>
<script>
export default {
    props: {
        dropdownHandle: {
            type:String,
            default: "Click"
        },
        actionId:{
            type:String,
            default:''
        },
        menuList:{
            type:Array,
            default:[{ link:'/', menuInner:'Menu 1' ,icon:''}]
        },
        callBacks:{
            type:Object,
            default:{}
        }
    },
    data () {
        return {
            isShow:false,
        }
    },
    methods: {
        toggler:function(){
            this.isShow = (this.isShow)?false:true
        },
        closeIfClickedOutside:function(event) {
            var self= this;
            if(this.$el.classList.contains('active') && !this.$el.contains(event.target) ){
                self.isShow = false
            }
        }
    },
    watch:{
       isShow:function(){
           if(this.isShow){
               document.addEventListener('click', this.closeIfClickedOutside);
           }
       }
    },
    mounted() {
        
    }
}
</script>