<template>
 <div class="loader-main" v-if="stat">
    <div class="lds-spinner" v-if="theme =='default'" >
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
    <div class="skeleton-feed" v-if="theme =='skeleton-feed'">
         <div class="cardbox shadow-xss w-100 mb-3 rounded-xxl" v-for="n in count" :key="n">
            <div class="cardbox-heading">
                <div class="media m-0">
                    <div class="d-flex mr-3"><figure><div class="img"></div></figure></div>
                    <div class="media-body details">
                         <span class="name"></span><span class="about"></span>
                    </div>
                </div>
            </div>
            <div class="cardbox-body">
                <div class="cardbox-item description">
                    <div class="line line-1"></div>
                    <div class="line line-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<style scoped>

.img{ 
  height: 42px;
  width: 42px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.skeleton-feed .cardbox .cardbox-item{
    padding:0px 0.88rem;
}
.details span{
  display: block;
  background: #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.details .name{
  height: 12px;
  width: 120px;
}
.details .about{
  height: 12px;
  width: 70px;
  margin-top: 10px;
}

.description .line{
  background: #d9d9d9;
  border-radius: 10px;
  height: 12px;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
}
.description .line-1{
  width: calc(100% - 15%);
}
.description .line-2{
  width: 100%;
  height: 14rem;
}
.description .line-3{
  width: 100% ;
}

.img::before,
.details span::before,
.description .line::before,
.btns .btn::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
  background-repeat: no-repeat;
  background-size: 450px 400px;
  animation: shimmer 1s linear infinite;
}
.img::before{
  background-size: 650px 600px;
}
.details span::before{
  animation-delay: 0.2s;
}
.btns .btn-2::before{
  animation-delay: 0.22s;
}
@keyframes shimmer {
  0%{
    background-position: -450px 0;
  }
  100%{
    background-position: 450px 0;
  }
}


</style>
<script>
export default {
    props: {
        stat: {
            type:Boolean,
            default: false
        },
        theme:{
            type:String,
            default:'default'
        },
        count:{
          type:Number,
          default:3
        }
    },
}
</script>