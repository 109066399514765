<template>
    <footer>
        <div class="ft-l1">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="logo text-center">
                        <a href="/"><img src="/theme/img/logo-white.svg" alt="" /> </a>
                    </div>
                </div>
                 <div class="col-md-2">
                    <ul class="footer-menu">
                        <li><a target="_blank" href="https://memecake.notion.site/About-Us-dca5cac5f97940938f66e20adec075e5">About Us</a></li>
                        <li><a target="_blank" href="https://memecake.notion.site/Meme-Cake-Roadmap-a9c49b58dba5405aa5065ba36f29a10b">Roadmap</a> </li>
                    </ul>
                 </div>
                  <div class="col-md-2">
                    <ul class="footer-menu">
                        <li><a target="_blank" href="https://memecake.notion.site/NFT-Staking-a836c44f9e074176b8b6af43e1681aee">NFT Staking</a></li>
                        <li><a target="_blank" href="https://www.notion.so/memecake/Token-Economics-0644e666372847559925bfcdbe0830e5">Token economics</a></li>
                        <li><a target="_blank" href="https://memecake.notion.site/Meme-Cake-NFT-s-7905c24ff0524dd7b052bc143f7c9d95">Rich & Icy</a> </li>
                    </ul>
                 </div>
                 <div class="col-md-2">
                    <ul class="footer-menu">
                        <li><a target="_blank" href="https://memecake.notion.site/FAQ-05f479dc689f47a7bbddb7e1ae4d516a">FAQ</a></li>
                        <li><a target="_blank" href="https://memecake.notion.site/Contact-Us-b47906d87e9248d08573b910b26e7321">Contact Us</a></li>
                        <li><a target="_blank" href="https://memecake.notion.site/memecake/Meme-Cake-Help-Center-9a712a74a45643cba080550e8041e3b9">Help Center</a></li>
                        
                    </ul>
                 </div>
                  <div class="col-md-2">
                    <ul class="footer-menu">
                        <li><a target="_blank" href="https://discord.gg/WFkRssvNtW">Discord</a></li>
                        <li><a target="_blank" href="https://twitter.com/realmemecake">Twitter</a></li>
                         <li><a target="_blank" href="https://t.me/meme_cake">Telegram</a></li>
                    </ul>
                 </div>
            </div>
        </div>
        </div>
        <div class="ft-l2">
         <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <p>© 2021 memecake. All rights reserved.</p>
                </div>
                 <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <!-- <ul>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms & Conditions</a></li>
                    </ul> -->
                </div>
            </div>
         </div>
        </div>
    </footer>
</template>