<template>
    <div class="post-master" >
        <div class="close-btn">
            <i class="mico-feather-12" @click.prevent="$parent.postFullView = false"></i>
        </div>
        <div class="post-full-view rounded-xxl" v-if="postData">
            <div class="container-fluid">
            <div class="row">
                <div class="col-lg-8">
                    <div class="media-viewer">
                        <div class="im-con">
                            <img :src="postData.attachment[0].file_url">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="post-info">
                        <div class="cardbox">
                            <div class="cardbox-heading">
                                <div class="media m-0">
                                    <div class="d-flex mr-3">
                                        <router-link :to="'/'+(postData.postAuthor.username?postData.postAuthor.username:postData.postAuthor._id)">
                                            <figure>
                                                <img class="img-circle w45" :src="postData.postAuthor.userDp?postData.postAuthor.userDp:'https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg'" alt=""/>
                                            </figure> 
                                        </router-link>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="m-0 u-name">
                                            <router-link :to="'/'+(postData.postAuthor.username?postData.postAuthor.username:postData.postAuthor._id)">{{postData.postAuthor.username}}</router-link>
                                        </h4>
                                        <small class=""><span>{{timeSince}}</span></small>
                                    </div>
                                    <div class="d-flex">
                                        <div class="d-inline-flex" v-if="userLoggedIn.userId==postData.postAuthor._id">
                                            <DropDown dropdownHandle='<i class="mico-android-more-horizontal"></i>' class="user-post-actions" :menuList="postMenu" :actionId="postData._id"></DropDown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cardbox-desc">
                                    <div class="media-desc" v-if="!postEditorActive">
                                        <div v-html="formatedPostDesc"></div>
                                        <a class="desc-togg" href="#" @click.prevent="togglFullText" v-if="postDescShorted">See more</a>
                                        <a class="desc-togg" href="#" @click.prevent="togglFullText" v-if="hideConToggler">See less</a>
                                    </div>
                                    <div class="inlineEdit" v-else>
                                        <textarea v-model="editContent" class="edit-post-content" placeholder="Write a caption..."></textarea>
                                        <div class="btn-area">
                                            <i class="mico-feather-15 save-p" @click.prevent="savePost"></i>
                                            <i class="mico-close-round cancel" @click.prevent="editPostCancel"></i>
                                        </div>
                                    </div>
                            </div>
                            <div class="post-actions">
                                <a href="#" @click.prevent="likeOrUnlike" class="act-like" :class="postData.liked?'acted':''">
                                    <i :class="postData.liked?'mico-line-awesome-3':'mico-feather-5'"></i>
                                    <span v-if="postData.likeCount>0">{{postData.likeCount}} Likes</span>
                                </a>
                                <a class="act-cmt">
                                    <i class="mico-feather-6"></i>
                                    <span v-if="postData.commentCount>0">{{postData.commentCount}} Comments</span>
                                </a>
                                <div class="has-dropdwon act-share" :class="(shareMenuShow)?'active':''">
                                        <a class="toggler" @click.prevent="shareMeunuToggle()">
                                            <i class="mico-feather-4"></i>
                                        </a>
                                        <ul class="dropdown  p-2 rounded-3 likely" :data-imageurl="postData.attachment[0].thumb?postData.attachment[0].thumb:postData.attachment[0].file_url" :data-url="shareBasUrl+'/p/'+postData._id"   :data-title="postData.postContent.substring(0,this.trimLen)">
                                            <li class="facebook" data-counter ="0">Share</li>
                                            <li class="twitter">Tweet</li>
                                            <li class="telegram">Send</li>
                                            <li class="pinterest" :data-media="postData.attachment[0].thumb?postData.attachment[0].thumb:postData.attachment[0].file_url">Pin</li>
                                            <li class="linkedin">Share</li>
                                            <li class="whatsapp">Send</li>
                                            <li class="reddit">Share</li>
                                            <li class="" @click.prevent="copyUrl">
                                                <input type="text" :value="shareBasUrl+'/p/'+postData._id" id="copyUrl">
                                                Copy Url</li>
                                        </ul>                                                        
                                </div>
                            </div>
                        </div>
                        <div class="post-comments">
                            <cmtViewer :postId="postData._id"></cmtViewer>
                        </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
#copyUrl{
    position: absolute;
    width: 0px;
    height: 0px;
    visibility: hidden;
    opacity: 0p;
    display: none;
}
    .post-master .close-btn{
        right: unset;
        left: 15px;
        z-index: 2;
        background: rgb(0 0 0 / 60%);
    }
    .post-full-view{
        z-index: 1;
    }
    .media-viewer{
        width: 100%;
        /*display: flex;
         flex-direction: column; */
        position: relative;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        background: var(--dark-color);
        align-items: center;
    }
    .im-con{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 96vh;
    }
    .im-con img{
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
        margin: auto;
        width: 100%;
    }
    .post-info{
        width: 100%;
        background: var(--white-clr);
        height: 96vh;
        overflow: scroll;
        overflow-x: hidden;
        position: relative;
    }
    .post-info .cardbox{
        min-height: 33vh;
        overflow: unset;
    }
    .cardbox .cardbox-heading{
        width: 100%;
        height: auto;
    }
    .post-info .cardbox-desc{
        padding: 0.88rem;
    }
    .act-share .dropdown{
        bottom: unset;
    }
    .post-comments{
    padding: 6px 0.88rem !important;
    width: 100%;
    }
    .post-info::-webkit-scrollbar {
        width: 10px;
    }

    .post-info::-webkit-scrollbar-track {
        background-color: #e4e4e4;
    }

    .post-info::-webkit-scrollbar-thumb {
        border-radius: 100px;
        border: 1px solid transparent;
        background-clip: content-box;
        background-color: var(--dark-color);
        min-height: 20vh;
    }
   @media (max-width: 768px)  {
       .post-full-view{
           padding: 0px;
       }
        .post-master{
                height: 100vh;
                overflow: scroll;
        }
        .im-con{
            display: block;
            height: auto;
        }
        .post-info{
            height: auto;
            overflow: unset;
        }
        .media-viewer{
            padding: 10px;
        }
    }
</style>
<script>
import helpers from '@/plugins/helpers.js'
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
import likely from '@/assets/js/likely.min.js'
export default {
    props: {
        postviewerStat:{type:Boolean , default:false},
        postData:{ type:Object , default:false},
    },
    components: {
		
	},
    computed: {
        ...mapGetters({ authToken: 'authToken',userLoggedIn:'userData'}),
        timeSince() { return helpers.timeSince(new Date(this.postData.postDate)) +' ago'},
    },
    mounted () {
       this.postConFormat();
       document.addEventListener("backbutton", this.yourCallBackFunction, false);
    },
    data() {
        return {
            postMenu:[
                { menuInner:'Edit Post' ,icon:'mico-feather-3',action:this.editPostInit},
                { menuInner:'Delete Post' ,icon:'mico-trash-bin',action:this.initDelete},
            ],
            postEditorActive:false,
            formatedPostDesc:'',
            trimLen:200,
            postDescShorted:false,
            hideConToggler:false,
            editContent:'',
            shareMenuShow:false,
            shareBasUrl:window.location.origin,
            likeAct:false
        }
    },
    methods: {
        backBtnHandler () {
            
        },
        initDelete(e){
            var self= this;
            swal({ title: "Delete Post?",text: "Are you sure you want to delete this post?",className: "post-delete-dialoge",
                icon: "warning",buttons: { cancel:'Cancel',  defeat:'Delete'},dangerMode: true}).then((willDelete) => {
                    if (willDelete) {
                        self.deletePost(e)
                    }
            });
        },
        editPostInit(e){
            this.postEditorActive = true;
            this.editContent = this.postData.postContent
        },
        postConFormat(){
            var text = (this.postData.postContent && this.postData.postContent !="undefined")?this.postData.postContent:''
            if(this.postDescShorted==false){
                text= text.substring(0, this.trimLen);
                if(this.postData.postContent.length>this.trimLen)  this.postDescShorted=true;
                 if(this.postData.postContent.split('\n').length >10) this.postDescShorted=true;
            }else{
                this.postDescShorted=false
            }
            text = text.replace(/(\b(https?|http):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/img, '<a href="$1" target="_blank">$1</a>');
            text = text.replace(/#(\w+)/g, '<a href="'+window.location.origin+'/hashtag/$1"> #$1 </a> '); 
            text = text.replace(/@(\w+)/g, '<a href="'+window.location.origin+'/$1"> @$1 </a> ');
            if(this.postDescShorted==false) text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            if(this.postDescShorted) text+='...'
            this.formatedPostDesc =  text;
        },
         togglFullText:function(){
            if(this.hideConToggler)this.hideConToggler = false
            else this.hideConToggler = true
            this.postConFormat();
        },
         async savePost(){
            var tags = this.findHashtags(this.editContent);
            //if (this.editContent == this.postData.postContent) return false;
            var formData = new FormData();
            formData.append('postId',this.postData._id);    
            formData.append('postContent',this.editContent);
            formData.append('tags',tags);   
            let resp= await axios.put('/api/userpost', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then();
            if(resp.data){
                this.postData.postContent = this.editContent;
                let objIndex = this.$parent.postList.findIndex((o) => o._id === this.postData._id);
                this.$parent.postList[objIndex] = this.postData;
                this.postEditorActive = false;
                this.postDescShorted = false,
                this.hideConToggler = false,
                this.postConFormat();
            }else{

            }

        },
         findHashtags:function (searchText) {
            var result = searchText.match(/#[a-z]+/gi);
            if(result){
                result = result.map(a => a.substring(0, 20).replace('#', ''))
                return result
            } 
            else return false
        },
        editPostCancel(){
             this.postEditorActive = false;
             this.editContent ="";
             this.postConFormat();
        },
        async deletePost(postId){
            var self = this;
            axios.delete('/api/userpost',  {data:{ postId: postId }}).then(res=>{ 
                let objIndex = self.$parent.postList.findIndex((o) => o._id === postId);
                if (objIndex > -1) { self.$parent.postList.splice(objIndex, 1) }
                self.$parent.postFullView = false
                swal("Post deleted.", {icon: "success"});
            }).catch(error=>{
                swal("Unable to delete post.", {icon: "error"});
            })
        },
        shareMeunuToggle:function(){
             this.shareMenuShow = (this.shareMenuShow)?false:true
             likely.initiate();
        },
        likeOrUnlike:async function(){
            if(this.likeAct) return false;
            this.postData.liked = this.postData.liked?false:true
            this.likeAct=true
            let resp= await axios.post('/api/postact/like/', {postId:this.postData._id}).then();
            if(resp.data){
                this.postData.liked = resp.data.data.liked;
                this.postData.likeCount = resp.data.data.likeCount
            }
            this.likeAct=false
        },
        copyUrl:function(){
            var copyText = document.getElementById("copyUrl");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
            this.shareMenuShow= false
        }
    },
    watch:{
        "$parent.postFullView":function(){
            if(this.$parent.postFullView) document.querySelector('body').classList.add('full-view');
        }
    },
    beforeDestroy () {
        document.removeEventListener("backbutton", this.yourCallBackFunction);
    }
}
</script>