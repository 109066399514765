<template>
  <router-view></router-view>
</template>

<script>
import 'noty/lib/noty.css'
import 'noty/lib/themes/mint.css'

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.isLoading = true;
  },
};
</script>
<style>
</style>