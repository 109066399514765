import axios from 'axios'
let auth = localStorage.getItem("BearerToken");
if (auth) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + auth
} else {
  delete axios.defaults.headers.common.Authorization;
}

axios.interceptors.response.use((response) => response, (error) => {
 console.log()
 if(error.response.data){
   if(error.response.data.code == "UNAUTHORIZED")  window.location.href = "/auth/login"
 }
 return Promise.reject(error);
});
export default axios
