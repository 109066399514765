<template>
<div>
  <header class="">
    <nav class="navbar">
      <div class="container">
        <div class="d-flex justify-content-lg-between menu-cont" :class="isMob?'mob-active':''">
          <div class="logo d-flex col-lg-2">
            <a href="/"><img src="/theme/img/logo-dark.svg" alt="" /> </a>
          </div>
          <div class="menu-pub col-lg-7"  v-if="!isMob" >
             <ul class="comm-menu" :class="(isShow)?'shown':''"> 
               <li><a href="/feed" exact-path data-title="Feed">Feed</a></li>  <!-- <i class="mico-feather"></i>   <i class="mico-line-awesome"></i>  <i class="mico-shop"></i> -->
               <li><a href="/launchpad" exact-path data-title="Launchpad"> Launchpad</a></li> 
               <li><a href="/marketplace" exact-path data-title="Marketplace">Marketplace </a></li>
               <!-- <li><router-link to="/staking" exact-path data-title="Staking"><i class="mico-feather-10"></i> </router-link></li> -->
               
             </ul>
          </div>
          <div class="menu-auth  text-end col-lg-3" :class="(isShow)?'shown':''" v-if="!isMob && currUrl !='Home'">
            <ul v-if="authToken" class="user-menu text-end justify-content-lg-end">
              <li><a href="#" @click.prevent="showCreatePost=(showCreatePost)?false:true" ><i class="mico-plus-square-o"></i></a></li>
              <li><router-link to="/mcakewallet"><i class="mico-logo"></i> </router-link></li>
              <li><router-link to="/settings/wallets"><i class="mico-vallet"></i> </router-link></li>
              <li><DropDown :dropdownHandle='userProfileMenu.main' class="profile-menu" :menuList="userProfileMenu.list"></DropDown></li>
            </ul>
            <nav class="text-start nav-menu comm-menu" v-else>
               <li><router-link to="/auth/login"> Login / Sign Up</router-link> </li>
            </nav>
             <nav class="user-menu text-end justify-content-lg-end"  v-if="currUrl == 'Home' ">
                <li><a href="/auth/login">Login / Sign Up</a></li>
             </nav>
          </div>
          <div class="menu-auth  text-end col-lg-3" :class="(isShow)?'shown':''" v-if="!isMob && currUrl =='Home' && !authToken">
             <nav class="user-menu text-end justify-content-lg-end"  v-if="currUrl == 'Home' ">
                <li><a href="/auth/login">Login / Sign Up</a></li>
             </nav>
          </div>
           <a href="#" class="mobilemenu"  v-on:click.stop.prevent="isShow = !isShow"><i class="mico-android-more-vertical"></i></a>
        </div>
      </div>
    </nav>
  </header>
  <createPost v-if="showCreatePost"></createPost>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import helpers from "@/plugins/helpers.js";
export default {
  components: {},
  data() {
     return {
      isShow:true,
      showCreatePost:false,
      isMob:helpers.isMobile()?true:false,
      currUrl: this.$route.name,
      userProfileMenu:{
        main:'<img class="user-dp" src="https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg">',
        list:[
          { link:'/', menuInner:'Profile' ,icon:'mico-user-outline'},
          { link:'/settings', menuInner:'Settings' ,icon:'mico-settings-streamline-1'},
          { link:'/settings/wallets', menuInner:'Manage Wallets' ,icon:'mico-vallet'},
          { link:'/logout', menuInner:'Logout' ,icon:'mico-sign-out'},
        ]
      }
    }
  },
  mounted () {
    //console.log()
    this.isShow =false;
    this.userProfileMenu.main = '<img class="user-dp" src="'+(this.userData.userDp?this.userData.userDp:'https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg')+'">'
    this.userProfileMenu.list[0].link="/"+(this.userData.username?this.userData.username:this.userData.userId)+''
  },
  methods: {},
  watch:{
    $route (to, from){
        this.isShow = false;
    },
    showCreatePost:function(){
      if(this.showCreatePost){
        document.querySelector('body').style.overflow="hidden"
      }else{
        document.querySelector('body').style.overflow=""
      }
    } ,
} ,
  computed: {
    ...mapGetters({
      authToken: 'authToken',
      userData:'userData'
    }),
  }
};
</script>