import { createRouter, createWebHistory } from 'vue-router'
import BaseLayout from '@/views/Layout/Base.vue'
import AuthLayout from '@/views/Layout/Auth.vue'
import store from '@/store'

const routes = [
  { path: '/auth', component: AuthLayout,
    children: [
      { path: '/auth/signup', beforeEnter: (to, from, next) => { if (!store.state.authToken) next(); else next('/feed')}, name: 'signup', component: () => import('@/views/Auth/SignUp')},
      { path: '/auth/login',  beforeEnter: (to, from, next) => { if (!store.state.authToken) next(); else next('/feed')}, name: 'login', component: () => import('@/views/Auth/SignIn')}, // forgetpassword
      { path: '/auth/forgetpassword', name: 'forgetpassword', component: () => import('@/views/Auth/ForgotPassword')},
    ]
  },
  {path: '/recoverpasswd/:pwdrecoverykey' ,name: 'recoverpasswd', component: () => import('@/views/Auth/ResetPass') },
  {
    path: '/', name: 'Default', beforeEnter: (to, from, next)=>{ next(); } , component: BaseLayout,
    children: [
      { path: '/', name: 'Home', component: () => import('@/views/Home/index')},
      { path: '/feed', name: 'Feed', component: () => import('@/views/Posts/Feed')},
      { path: '/p/:postid', name: 'PostView',  component: () => import('@/views/Posts/postFullview') },
      { path: '/hashtag/:hashtag', name: 'HashPosts',  component: () => import('@/views/Posts/HashFeed') , beforeRouteLeave (to, from, next) { return false} },
      { path: '/mcakewallet', name: 'mcakewallet',  component: () => import('@/views/Wallet/mcakewallet') },
      { path: '/pokedcake', name: 'poked',  component: () => import('@/views/Presales/poked') },
      { path: '/cakesquad', name: 'cakesquad',  component: () => import('@/views/Presales/cakesquad') },
      { path: '/launchpad',beforeEnter: (to, from, next) => { if (store.state.authToken) next();else next('/auth/login')}, name: 'launchpad', component: () => import('@/views/Others/Launchpad') },
      { path: '/marketplace', name: 'marketplace', component: () => import('@/views/Others/Marketplace') },
      { path: '/staking', name: 'staking', component: () => import('@/views/Others/Staking') },
      { path: '/:userid', name: 'user_pub_profile', component: () => import('@/views/User/UserProfile') },
      { path: '/settings',beforeEnter: (to, from, next) => {if (store.state.authToken) next();else next('/auth/login')}, 
        name: 'settings',component: () => import('@/views/AccountSettings/Index'),
        children: [
          { name:'SettingsHome', path: '/settings/', component: () => import('@/views/AccountSettings/Default')},
          { name:'Prfile_edit', path: '/settings/editprofile', component: () => import('@/views/AccountSettings/ProfileEdit')},
          { name:'ChangePassword', path: '/settings/password', component: () => import('@/views/AccountSettings/PasswordChange')},
          { name:'wallets', path: '/settings/wallets', component: () => import('@/views/AccountSettings/WalletsManage')},
        ],   
      },
      { path: '/create',beforeEnter: (to, from, next) => { if (store.state.authToken) next();else next('/auth/login')}, name: 'Prfile', component: () => import('@/views/Posts/CreatePost') },
      { path: '/logout',beforeEnter: (to, from, next) => { if (store.state.authToken) next();else next('/auth/login')}, name: 'logOut', component: () => import('@/views/Auth/Logout') },
    ]
  },
  { path: '/:pathMatch(.*)*', name: 'notfound', component: () => import('@/views/NotFound') },
  { path: '/404', name: '404', component: () => import('@/views/NotFound') }
]

const router = createRouter({ history: createWebHistory(process.env.BASE_URL), routes , scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}})

export default router
