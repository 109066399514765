<template>
    <div class="cardbox shadow-xss w-100 mb-3 rounded-xxl">
        <div class="cardbox-heading">
            <div class="media m-0">
                <div class="d-flex mr-3">
                    <router-link :to="'/'+(postItem.postAuthor.username?postItem.postAuthor.username:postItem.postAuthor._id)">
                        <figure>
                            <img class="img-circle w45" :src="postItem.postAuthor.userDp?postItem.postAuthor.userDp:'https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg'" alt=""/>
                        </figure> 
                    </router-link>
                </div>
                <div class="media-body">
                    <h4 class="m-0 u-name">
                        <router-link :to="'/'+(postItem.postAuthor.username?postItem.postAuthor.username:postItem.postAuthor._id)">{{postItem.postAuthor.username}}</router-link>
                    </h4>
                    <small class=""><span>{{timeSince}}</span></small>
                </div>
                <div class="d-flex">
                    <div class="d-inline-flex" v-if="userLoggedIn.userId==postItem.postAuthor._id">
                        <DropDown dropdownHandle='<i class="mico-android-more-horizontal"></i>' class="user-post-actions" :menuList="postMenu" :actionId="postItem._id"></DropDown>
                    </div>
                </div>
            </div>
            <div class="cardbox-desc">
                <div class="media-desc" v-if="!postEditorActive">
                    <div  v-html="formatedPostDesc"></div>
                    <a class="desc-togg" href="#" @click.prevent="togglFullText" v-if="postDescShorted">See more</a>
                    <a class="desc-togg" href="#" @click.prevent="togglFullText" v-if="hideConToggler">See less</a>
                </div>
                <div class="inlineEdit" v-else>
                    <textarea v-model="editContent" class="edit-post-content" placeholder="Write a caption..."></textarea>
                     <div class="btn-area">
                        <i class="mico-feather-15 save-p" @click.prevent="savePost"></i>
                        <i class="mico-close-round cancel" @click.prevent="editPostCancel"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="cardbox-body">
            <div v-if="postItem.attachment.length==1" class="cardbox-item">
                <a href="#" @click.prevent="openFullView">
                    <div v-if="['image/png' , 'image/gif' , 'image/jpeg','image/jpg' ,'image/jfif'].includes(postItem.attachment[0].mimeType.toLowerCase())">
                        <img class="im-cb-100 rounded-3" :src="postItem.attachment[0].thumb?postItem.attachment[0].thumb:postItem.attachment[0].file_url" alt="">
                    </div>
                </a>
            </div>
            <div class="post-actions">
                <a href="#" @click.prevent="likeOrUnlike" class="act-like" :class="postItem.liked?'acted':''">
                    <i :class="postItem.liked?'mico-line-awesome-3':'mico-feather-5'"></i>
                    <span v-if="postItem.likeCount>0">{{postItem.likeCount}} Likes</span>
                </a>
                <a href="#" class="act-cmt" @click.prevent="openFullView">
                    <i class="mico-feather-6"></i>
                    <span v-if="postItem.commentCount>0">{{postItem.commentCount}} Comments</span>
                </a>
                <div class="has-dropdwon act-share" :class="(shareMenuShow)?'active':''">
                        <a class="toggler" @click.prevent="shareMeunuToggle()">
                            <i class="mico-feather-4"></i>
                        </a>
                        <ul class="dropdown  p-2 rounded-3 likely" :data-imageurl="postItem.attachment[0].thumb?postItem.attachment[0].thumb:postItem.attachment[0].file_url" :data-url="shareBasUrl+'/p/'+postItem._id"   :data-title="postItem.postContent.substring(0,this.trimLen)">
                            <li class="facebook" data-counter ="0">Share</li>
                            <li class="twitter">Tweet</li>
                            <li class="telegram">Send</li>
                            <li class="pinterest" :data-media="postItem.attachment[0].thumb?postItem.attachment[0].thumb:postItem.attachment[0].file_url">Pin</li>
                            <li class="linkedin">Share</li>
                            <li class="whatsapp">Send</li>
                            <li class="reddit">Share</li>
                        </ul>                                                        
                </div>
            </div>
        </div>
    </div>
</template>
<style  >
    .post-delete-dialoge .swal-button.swal-button--defeat{
        background-color: var(--app-text-default-color);
    }
    .inlineEdit{
        width: 100%;
        display: flex;
    }
.inlineEdit textarea {
    width: 100%;
    height: 100px;
    resize: none;
    border: 1px solid var(--text-grey);
    padding: 5px;
    border-radius: 6px;
    line-height: 1.5;
}
   .inlineEdit textarea::-webkit-scrollbar {
       width: 10px;
    }
    .inlineEdit textarea::-webkit-scrollbar-thumb {
        border-radius: 100px;
        border: 1px solid transparent;
        background-clip: content-box;
        background-color: var(--dark-color);
        cursor: pointer;
        z-index: 2;
    }
     .inlineEdit .btn-area{
        display: inline-block;
        padding:0px 10px;
    }
.inlineEdit .btn-area i {
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    line-height: 30px;
    background: var(--primary-color);
    margin-bottom: 10px;
    color: var(--white-clr);
    font-size: 24px;
    line-height: 2;
    border-radius: 4px;
    cursor: pointer;
}
.inlineEdit .btn-area i.cancel{
    background: var(--dark-color);
     font-size: 18px;
     line-height: 2.5;
}
</style>
<script>
import helpers from '@/plugins/helpers.js'
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
import swal from 'sweetalert';
import likely from '@/assets/js/likely.min.js'
export default {
    props: {
        postItem: {
            type:Object,
            default: {}
        },
    },
     components: {
       
     },
    computed: {
        ...mapGetters({ authToken: 'authToken',userLoggedIn:'userData'}),
      timeSince() {
          return helpers.timeSince(new Date(this.postItem.postDate)) +' ago'
      },
    },
    data () {
        return {
            postMenu:[
                { menuInner:'Edit Post' ,icon:'mico-feather-3',action:this.editPostInit},
                { menuInner:'Delete Post' ,icon:'mico-trash-bin',action:this.initDelete},
            ],
            formatedPostDesc:'',
            postDescShorted:false,
            hideConToggler:false,
            postEditorActive:false,
            editContent:'',
            shareMenuShow:false,
            shareBasUrl:window.location.origin,
            trimLen:148,
            likeAct:false
        }
    },
    methods: {
        postConFormat(){
            var text = (this.postItem.postContent && this.postItem.postContent !="undefined")?this.postItem.postContent:''
            if(this.postDescShorted==false){
                text= text.substring(0, this.trimLen);
                if(this.postItem.postContent.length>this.trimLen)  this.postDescShorted=true;
                if(this.postItem.postContent.split('\n').length >4) this.postDescShorted=true;
                
            }else{
                this.postDescShorted=false
            }
            text = text.replace(/(\b(https?|http):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/img, '<a href="$1" target="_blank">$1</a>');
            text = text.replace(/#(\w+)/g, '<a href="'+window.location.origin+'/hashtag/$1"> #$1 </a> '); 
            text = text.replace(/@(\w+)/g, '<a href="'+window.location.origin+'/$1"> @$1 </a> ');
            if(this.postDescShorted==false) text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
            if(this.postDescShorted) text+='...'
            this.formatedPostDesc =  text;
        },
        togglFullText:function(){
            if(this.hideConToggler)this.hideConToggler = false
            else this.hideConToggler = true
            this.postConFormat();
        },
        initDelete(e){
            var self= this;
            swal({ title: "Delete Post?",text: "Are you sure you want to delete this post?",className: "post-delete-dialoge",
                icon: "warning",buttons: { cancel:'Cancel',  defeat:'Delete'},dangerMode: true}).then((willDelete) => {
                    if (willDelete) {
                        self.deletePost(e)
                    }
            });
        },
        editPostInit(e){
            this.postEditorActive = true;
            this.editContent = this.postItem.postContent
        },
        async savePost(){
            var tags = this.findHashtags(this.editContent);
            //if (this.editContent == this.postItem.postContent) return false;
            var formData = new FormData();
            formData.append('postId',this.postItem._id);    
            formData.append('postContent',this.editContent);
            formData.append('tags',tags);   
            let resp= await axios.put('/api/userpost', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then();
            if(resp.data){
                this.postItem.postContent = this.editContent;
                this.postEditorActive = false;
                this.postDescShorted = false,
                this.hideConToggler = false,
                this.postConFormat();
            }else{

            }

        },
        findHashtags:function (searchText) {
            var result = searchText.match(/#[a-z]+/gi);
            if(result){
                result = result.map(a => a.substring(0, 20).replace('#', ''))
                return result
            } 
            else return false
        },
        editPostCancel(){
             this.postEditorActive = false;
             this.editContent =""
        },
        async deletePost(postId){
            var self =this;
            axios.delete('/api/userpost',  {data:{ postId: postId }}).then(res=>{ 
                let objIndex = self.$parent.postList.findIndex((o) => o._id === postId);
                if (objIndex > -1) { self.$parent.postList.splice(objIndex, 1) }
                swal("Post deleted.", {icon: "success"});
            }).catch(error=>{
                swal("Unable to delete post.", {icon: "error"});
            })
        },
        shareMeunuToggle:function(){
             this.shareMenuShow = (this.shareMenuShow)?false:true
             likely.initiate();
        },
        likeOrUnlike:async function(){
            if(this.likeAct) return false;
            this.postItem.liked = this.postItem.liked?false:true
            this.likeAct=true
            let resp= await axios.post('/api/postact/like/', {postId:this.postItem._id}).then();
            if(resp.data){
                this.postItem.liked = resp.data.data.liked;
                this.postItem.likeCount = resp.data.data.likeCount
            }
            this.likeAct=false
        },
        openFullView:async function(){
            this.$parent.postFullView =true;
            this.$parent.postFullViewData =this.postItem
        }
    },
    watch:{
        
    },
    mounted () {
       this.postConFormat()
    },
}
</script>   