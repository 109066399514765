import { createStore } from 'vuex'


function setCookie(name, value, days=false) {
  var expires = "";
  if (days) {
    var date = new Date();
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export default createStore({
  state: {
    authToken: false,
    userData: {},
    canisterId: process.env.VUE_APP_canisterId,
    appUrl:process.env.VUE_APP_URL,
    mintAddress:process.env.VUE_APP_TokenMint,
    solChain:process.env.VUE_APP_SolChain,
    plugWallet:{}
  },
  mutations: {
    setUserData (state, userData) {
      state.userData = userData;
      localStorage.setItem("UserData", JSON.stringify(userData));
      //setCookie('UserData',JSON.stringify(userData));
    },
    setAuthToken (state, authvar) {
      state.authToken = authvar
      localStorage.setItem("BearerToken", authvar);
      //setCookie('BearerToken',authvar);
    }
  },
  actions: {
    userData(context, userData) {
      //var userInfo = JSON.parse(getCookie('UserData'));
      var userInfo = JSON.parse(localStorage.getItem('UserData'));
      if(userData.fullName) userInfo.fullName = userData.fullName;
      if(userData.userDp) userInfo.userDp = userData.userDp;
      if(userData.username) userInfo.username = userData.username;
      context.commit('setUserData', userInfo);

    },
    regAuth(context, authData) {
      context.commit('setAuthToken', authData.token)
      var userData = { fullName: authData.fullName, userDp: authData.userDp, username: authData.username,userId: authData.userId }
      context.commit('setUserData', userData)
    },
    refreshToken(context) {
      var token = localStorage.getItem('BearerToken') //getCookie('BearerToken');
      if (token) {
        var userData = JSON.parse( localStorage.getItem('UserData'));
        context.commit('setAuthToken', token)
        context.commit('setUserData', userData)
      }
    }
  },
  modules: {
  },
  getters:{ 
    userData: (state) => {
      return state.userData
    },
    authToken: (state) => {
      return  state.authToken
    },
    
  }
})
