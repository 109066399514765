<template>
    <div class="post-master" >
        <div class="close-btn">
            <i class="mico-close-round" @click.prevent="$parent.showCreatePost = false"></i>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="create-post-contaner">
                        <div class="pm-box">
                           <div class="pm-box-header">
                               <h3>Create new post</h3>
                                <a v-if="postMedia"  class="btn share-postbtn" href="#" @click="publishPost">Share</a>
                           </div>
                           <div class="pm-box-body">
                               <div class="post-media" >
                                   <div class="post-step1" >
                                       <div class="p1s1-dflt" >
                                           <label for="uploadmedia" v-if="!postMedia"> 
                                               <i class="mico-images"></i>
                                               <p>Select your image</p>
                                            </label>
                                           <input id="uploadmedia" class="fileupload" type="file"  @change="onFileUpload" accept="image/*" />
                                       </div>
                                      <div class="d-flex post-edit-area">
                                           <Loader :stat='loader' background="transparent" spinner="var(--primary-color)"></Loader>
                                           <div id="image-editor" v-if="postMedia">
                                               <Cropper v-if="imageEditor"  ref="cropper" class="cropper" :src="fileUrl" :min-width="editorStenProps.minWidth" :maxWidth="maxWidth" :stencil-props="editorStenProps"/>
                                                <img v-else :src="fileUrl">
                                                <div class="editor-menu">
                                                    <div class="has-dropdwon" :class="(editorMenuisShow)?'active':''">
                                                         <a class="btn" @click.prevent="editorMenutoggler()">
                                                             <i class="mico-feather-19"></i>
                                                         </a>
                                                         <ul class="dropdown  p-2 rounded-3" v-if="editorMenuisShow">
                                                            <li :class="editorStenProps.aspectRatio==false?'active':''"><a href="#" @click.prevent="changeAR(4)"> Original </a></li>
                                                             <li :class="editorStenProps.aspectRatio==(1/1)?'active':''"><a href="#" @click.prevent="changeAR(1)"> 1 : 1 </a></li>
                                                             <li :class="editorStenProps.aspectRatio==(16/10)?'active':''"><a href="#"  @click.prevent="changeAR(2)"> 16 : 10 </a></li>
                                                             <li :class="editorStenProps.aspectRatio==(4/5)?'active':''"><a href="#"  @click.prevent="changeAR(3)"> 4 : 5 </a></li>
                                                         </ul>                                                        
                                                    </div>
                                                    <div class="has-dropdwon menu2" :class="(editorMenu2isShow)?'active':''" @mouseover="editorMenu2isShow=true" @mouseleave="editorMenu2isShow=false">
                                                         <a class="btn" @click.prevent="editorMenutoggler2()" >
                                                             <i class="mico-feather-7"></i>
                                                         </a>
                                                         <ul class="dropdown  p-2 rounded-3" >
                                                             <li><a href="#" @click.prevent="changeAngle(1)"><i class="mico-feather-17"></i> </a></li>
                                                             <li><a href="#"  @click.prevent="changeAngle(2)"> <i class="mico-feather-20"></i></a></li>
                                                             <li><a href="#"  @click.prevent="changeAngle(3)"><i class="mico-line-awesome-1"></i></a></li>
                                                             <li><a href="#"  @click.prevent="changeAngle(4)"><i class="mico-line-awesome-2"></i></a></li>
                                                         </ul>                                                        
                                                    </div>
                                                </div>
                                                <label for="uploadmedia" class="change-img"> <i class="mico-feather-16"></i> </label>
                                           </div>
                                           <div id="text-editor" v-if="postMedia">
                                               <div class="container">
                                                   <div class="row">
                                                   <div class="col-lg-12">
                                                       <div class="user-info-sm">
                                                           <img class="user-dp" :src="userData.userDp">
                                                           <p>{{userData.username}}</p>
                                                       </div>
                                                        <textarea v-model="postContent" placeholder="Write a caption..."></textarea>
                                                    </div>
                                                    <div class="col-lg-12">
                                                   
                                                    </div>
                                               </div>
                                               </div>
                                           </div>
                                      </div>
                                   </div>
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.cropper {
	background: #DDD;
    width: 100%;
}
#image-editor{
    width: 60%;
    display: inline-flex;
     overflow: hidden;
    border-bottom-left-radius: 12px;
    position: relative;
    height: 60vh;
    background: var(--text-grey2);
}
#image-editor .change-img {
    position: absolute;
    color: var(--primary-color);
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    background: var(--dark-color);
    padding: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 19px;
}
#image-editor .change-img p{
    margin: 0px 5px ;
}
#image-editor img{
    margin: auto;
    display: block;
    max-width: 100%;
    user-select: none;
    pointer-events: none;
}
#text-editor{
    width: 40%;
    display: inline-block;
    position: relative;
}
#text-editor .container{
    padding: 0;
}
#text-editor .row{
    width: 100%;
    margin: 0;
    padding-top: 14px;
    padding-bottom: 14px;
}

#text-editor textarea{
    text-align: left;
    border: none;
    width: 100%;
    height: 100%;
    resize: none;
    margin-top: 12px;
}
#text-editor textarea::-webkit-scrollbar {
    width: 10px;
   
}
#text-editor textarea::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 1px solid transparent;
    background-clip: content-box;
    background-color: var(--dark-color);
    min-height: 20vh;
}
.share-postbtn {
    width: 100%;
    text-align: center;
    background: var(--primary-color);
    color: var(--body-background);
    position: absolute;
    width: 100px;
    right: 8px;
    top: 8px;
    padding: 2px;
    font-size: var(--default-text-size);
}
.user-info-sm{
    width: 100%;
    display: flex;
}
.user-info-sm img {
    background: var(--text-grey2);
    width: 32px;
    border-radius: 50%;
    display: inline-flex;
}
.user-info-sm p{
    display: inline-flex;
    margin: 0px;
    font-weight: 600;
    margin: 5px 10px;
}

.editor-menu .has-dropdwon{
    position: absolute;
    color: var(--primary-color);
    left: 10px;
    bottom: 10px;
    cursor: pointer;
    background: var(--dark-color);
    padding: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 19px;
}
.editor-menu .has-dropdwon.menu2{
    left: 60px;
}
.editor-menu .btn{
    padding: 0;
}
.editor-menu .dropdown{
    top: unset;
    bottom: 100%;
    right: unset;
    left: 0;
    background: rgb(0 0 0 / 74%);
}
.editor-menu .dropdown li{
    margin-bottom: 10px;
}
.editor-menu .dropdown li a{
    font-size: 14px;
    color: var(--white-clr);
}
.editor-menu .dropdown li a i{
    font-size: 20px;
}
.editor-menu .dropdown li.active a{
    color: var(--secondary-color);
    font-weight: 500;
}
.post-edit-area{
    position: relative;
}
.post-edit-area .loader-main{
    z-index: 5;
}
@media (max-width: 768px) {
    .post-edit-area{
       display: block !important;
    }
     #image-editor {
         min-height: 50vh;
     }
    #image-editor , #text-editor{
        width: 100%;
        display: inline-block;
    }
    #text-editor textarea{
        height: auto;
    }
}
</style>
<script>
import { mapGetters } from 'vuex'
import { Cropper } from '@/assets/js/vue-cropper.js'
import '@/assets/css/vue-cropper.css';
import noty from 'noty'
import axios from '@/plugins/axios'

export default {
    components: {
		Cropper,
	},
    data() {
        return {
            loader:false,
            showCreatePost:this.$parent.showCreatePost,
            postMedia:false,
            postContent:'',
            tags:[],
            imageEditor:false,
            editorMenuisShow:false,
            editorMenu2isShow:false,
            maxWidth:2200,
            editorStenProps:{ aspectRatio: false , minWidth:480 , autoZoom:true ,},
            fileUrl:'',
            editorMenu:[
                { menuInner:'1 : 1' ,icon:'mico-feather-18',action:this.changeAR(1/1)},
            ]
        }
    },
    mounted () {
        
    },
    methods: {
        changeAR:function(rat){
            if(this.imageEditor){
                if(rat == 1) this.editorStenProps.aspectRatio=1/1
                else if(rat == 2) this.editorStenProps.aspectRatio=16/10
                else if(rat == 3) this.editorStenProps.aspectRatio=4/5
                else if(rat == 4) this.editorStenProps.aspectRatio=false
                this.$refs.cropper.refresh();
            }
            this.editorMenuisShow=false
        },
        changeAngle:function(ang){
             if(this.imageEditor){
                  if(ang == 1) this.$refs.cropper.rotate(90)
                  else if(ang == 2)  this.$refs.cropper.rotate(-90)
                  else if(ang == 3)  this.$refs.cropper.flip(true,  false);
                  else if(ang == 4)  this.$refs.cropper.flip(false, true);   
             }
             
        },  
         onFileUpload:async function(event) {
           var self=this
           this.postMedia = event.target.files[0];
           var reader  = new FileReader();
           if(!this.postMedia) return false
           if(this.postMedia.size > 10240000){
               new noty({text: 'File size should be less than 5 Mb' , type:"error", layout:"bottomRight" , timeout:1000}).show();
               this.postMedia = false
               return false
           }
           if( !(/image\/(jpe?g|tiff?|png|webp|bmp|jfif|gif)$/i).test(self.postMedia.type) ){
               return false
           }
           await reader.readAsDataURL(this.postMedia);
           reader.onload = function (e) {
               if(e.target.result){
                   if(self.postMedia.type =="image/gif" || self.postMedia.type =="image/svg+xml"){
                       self.imageEditor =false;
                       self.fileUrl = e.target.result
                   }
                   else if( (/image\/(jpe?g|tiff?|png|webp|bmp)$/i).test(self.postMedia.type) ){
                       self.imageEditor =true;
                       self.fileUrl = e.target.result
                   }
               }
            }
         },
         editorMenutoggler:function(){
            this.editorMenuisShow = (this.editorMenuisShow)?false:true
        },
         editorMenutoggler2:function(){
            this.editorMenu2isShow = (this.editorMenu2isShow)?false:true
        },
         publishPost:async function(){
                let self= this
                self.loader=true
                var formData = new FormData();
                formData.append('postContent',self.postContent);
                if(this.imageEditor){
                    var imgResult = this.$refs.cropper.getResult();
                    formData.append("attachment",self.dataURItoBlob(imgResult.canvas.toDataURL("image/jpeg")) );
                }else{
                    formData.append("attachment",self.postMedia );
                }
               
                formData.append('privacy','public');
                formData.append('tags', self.findHashtags(self.postContent));

                axios.post('/api/userpost', formData, {headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress: function (evnt) {  console.log('Updating package - ' + Math.round(evnt.loaded / evnt.total * 100) + '%');}
                }).then(resp => {
                    self.loader=false;
                    self.$parent.showCreatePost = false
                    self.postMedia =false,
                    self.postContent='',
                    new noty({text: 'Posted' , type:"success", layout:"bottomRight" , timeout:1000}).show();
                    self.$router.push('/'+(self.userData.username?self.userData.username:self.userData.userId));
                }).catch(error=>{
                    self.loader=false;
                    console.log()
                    new noty({text: error.response.data.message , type:"error", layout:"bottomRight" , timeout:1000}).show();
                })
            
         },
         dataURItoBlob: function(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], {type: mimeString });
        },
        findHashtags:function (searchText) {
            var result = searchText.match(/#[a-z]+/gi);
            if(result){
                result = result.map(a => a.substring(0, 20).replace('#', ''))
                return result
            } 
            else return false
        },
    },
    watch:{
        showCreatePost:function(){
            
        }} ,
    computed: {
        ...mapGetters({
        authToken: 'authToken',
        userData:'userData'
    })
  }
}
</script>