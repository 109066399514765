var dotenv = require('dotenv')
dotenv.config()
import store from './store'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import components from './components'
import './registerServiceWorker'


store.dispatch('refreshToken')
const app = createApp(App);
app.use(store).use(router)

components.register(app)
app.mount('#app')
